@import 'base/colors';
@import 'base/fonts';
@import 'base/media';
@import 'base/mixin';
@import 'base/fonts';
@import 'base/animate';

@import './base/export';

.fa-btn {
	border: 1px solid;
	@apply rounded-full py-1.5 px-4 text-sm font-normal
	 border-secondary border-opacity-10;
	.fa-icon {
		@apply w-5;
	}
}

.loading-cover {
	min-height: 300px;
	@apply bg-secondary;
}

.panel-loader-wrapper {
	@apply animate-fade-in;
}

.rs-panel-group .rs-panel + .rs-panel:before {
	@apply border-0;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
	box-shadow: none !important;
}

code,
code span {
	font-size: 12px !important;
	&.language-python *,
	& {
		font-weight: 400 !important;
		font-family: 'Source Code Pro' !important;
		background: transparent !important;
	}
}
